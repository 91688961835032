import React from "react";
import img from "../../assets/logo.svg";

const Header = () => {
  return (
    <header>
      <div className="header center-cont">
        <img src={img} alt="logo" className="header-logo" />
      </div>
    </header>
  );
};

export default Header;
