import React, { useEffect, useState } from "react";
import "./Promo.scss";
import img from "../../assets/wheel.png";
const Promo = ({ handleClick }) => {
 

  const CopyButton = ({ text }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(text);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };

    return (
      <button
        onClick={handleCopyClick}
        disabled={isCopied}
        className="button-36"
      >
        <p>{isCopied ? "COPIED" : "Copy Promocode"}</p>
      </button>
    );
  };

  return (
    <div className="promo center-cont">
      <div className="promo-cont">
        <h1>Play The Dog House online</h1>
        <div>
          <p>Bonus 375% + 150 FS</p>
          <p>
            Promocode: <span>THESWEET</span>
          </p>
        </div>
        <div>
          <CopyButton text="THESWEET"/>
          <button className="button-36" onClick={handleClick}>
            Where to play?
          </button>
        </div>
      </div>
      <div className="promo-img">
        <img src={img} alt="img" />
      </div>
    </div>
  );
};

export default Promo;
