import React, { useEffect, useRef, useState } from "react";
import daddy from "../../assets/daddy.svg";
import gamma from "../../assets/gama.svg";
import cat from "../../assets/сat.svg";
import "./WhereToPlay.scss";

const WhereToPlay = ({scrollComponent2}) => {
  const pRef = useRef(null);

  useEffect(() => {
    if (scrollComponent2 && pRef.current) {
      pRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollComponent2]);

  const CopyButton = ({ text }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(text);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };

    return (
      <button
        onClick={handleCopyClick}
        disabled={isCopied}
        className="button-36"
      >
        <p>{isCopied ? "Copied" : "Promocode"}</p>
        <p>Not required</p>
      </button>
    );
  };

  return (
    <>
      <div className="play-logo" ref={pRef}>
        <span />
        <p>Where to  play?</p>
      </div>
      <div className="play center-cont">
        <div className="play-daddy">
          <img src={daddy} alt="daddy" />
          <div className="play-promo-text">
            <p>Bonus 375% + 150 FS</p>
          </div>
          <div className="play-promo">
            <CopyButton text="THESWEET" />
            <div>
              <a href="https://nice-road-two.com/d74076b1c " target="_blank">
                <button className="button-36">Play</button>
              </a>
            </div>
          </div>
        </div>
        <div className="play-gama">
          <img src={gamma} alt="daddy" />
          <div className="play-promo-text">
            <p>Bonus 375% + 150 FS</p>
          </div>
          <div className="play-promo">
            <CopyButton text="THESWEET" />
            <div>
              <a href="https://colorful-road-three.com/d35db545f " target="_blank">
                <button className="button-36">Play</button>
              </a>
            </div>
          </div>
        </div>
        <div className="play-cat">
          <img src={cat} alt="daddy" />
          <div className="play-promo-text">
            <p>Bonus 375% + 150 FS</p>
          </div>
          <div className="play-promo">
            <CopyButton text="THESWEET" />
            <div>
              <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
                <button className="button-36">Play</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhereToPlay;
