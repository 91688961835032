import React from "react";
import './MaxWin.scss'
import win from "../../assets/winn.jpg"

const MaxWin = () => {
  return (
    <>
      <div className="money-logo">
        <span />
        <p>The maximum win for yesterday using our promo code:</p>
      </div>
      <div className="money center-cont">
        <div>
            <img src={win} alt="win" />
        </div>
      </div>
    </>
  );
};

export default MaxWin;
