import React, { useEffect, useState } from "react";
import "./Footer.scss";
import img1 from "../../assets/logo.svg";

const Footer = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer center-cont">
        <h3>Often looking for</h3>
        <ul>
          <a href="/">The official website of The Dog House</a>
          {/* <a href="">The Dog House Slot Machines</a> */}
          <a href="/bonus">Bonus for The Dog House slot</a>
          <a href="/casino">The official casino of The Dog House slot </a>
          <a href="/demo">Demo slot The Dog House </a>
          <a href="/download">Download The Dog House slot </a>
          <a href="/play">Play The Dog House slot</a>
          <a href="/slot">The Dog House Official slot </a>
          <a href="/wegaways">
            The official website of The Dog House Megaways slot{" "}
          </a>
          <a href="/megaways-demo">The Dog House Megaways Demo Slot </a>
          <a href="/megaways-play">Play online Dog House Megaways </a>
          <a href="/megaways-money">Play for money in Dog House Megaways </a>
          <a href="/megaways-offical">
            The official website of The Dog House Megaways slot{" "}
          </a>
          <a href="/megaways-online">The Dog House Megaways Online Slot </a>
          <a href="/megaways-slot">
            The official slot of The Dog House Megaways
          </a>
          <a href="/multihold">The official slot of The Dog House Multihold</a>
          <a href="/multihold-demo">The Dog House Multihold Demo Slot </a>
        </ul>
        <div className="logo-text">
          <img src={img1} className="footer-logo" />
          <p>thedoghouse-official.com © {currentYear} Official Dog House website</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
