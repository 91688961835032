import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import SlotIcons from "../components/slotIcons/SlotIcons";
import MaxWin from "../components/maxWin/MaxWin";
import WhereToPlay from "../components/whereToPlay/WhereToPlay";
import Promo from "../components/promo/Promo";
import Header from "../components/header/Header";

const Layout = () => {
  const [scrollComponent2, setScrollComponent2] = useState(false);

  const handleButtonClick = () => {
    // Set the state to trigger re-render of Component2
    setTimeout(() => {
      setScrollComponent2(false);
    }, 1000);
    setScrollComponent2(true);
  };
  return (
    <>
      <Header />
      <Promo handleClick={handleButtonClick} />
      <WhereToPlay scrollComponent2={scrollComponent2} />
      <MaxWin />
      <SlotIcons />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
