import React from "react";
import "./SlotIcons.scss";
import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/5.png";
import img6 from "../../assets/6.png";
import img7 from "../../assets/7.png";
import img8 from "../../assets/8.png";
import img9 from "../../assets/9.png";
import img10 from "../../assets/10.png";
import img11 from "../../assets/11.png";
import img12 from "../../assets/12.png";
import img13 from "../../assets/13.png";

const SlotIcons = () => {
  return (
    <>
      <div className="slots-logo">
        <span />
        <p>Symbols and coefficients</p>
      </div>
      <div className="slots center-cont">
        <div className="slots-cont">
          <div>
            <img src={img1} alt="img" />
            <div>
              <p>3</p>
              <p>x2.50</p>
            </div>
            <div>
              <p>4</p>
              <p>x7.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x37.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img2} alt="img" />
            <div>
              <p>3</p>
              <p>x1.75</p>
            </div>
            <div>
              <p>4</p>
              <p>x5.00</p>
            </div>
            <div>
              <p>5</p>
              <p>x25.00</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img3} alt="img" />
            <div>
              <p>3</p>
              <p>x1.50</p>
            </div>
            <div>
              <p>4</p>
              <p>x3.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x15.00</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img4} alt="img" />
            <div>
              <p>3</p>
              <p>x2.50</p>
            </div>
            <div>
              <p>4</p>
              <p>x7.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x37.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img5} alt="img" />
            <div>
              <p>3</p>
              <p>x0.60</p>
            </div>
            <div>
              <p>4</p>
              <p>x1.25</p>
            </div>
            <div>
              <p>5</p>
              <p>x7.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img6} alt="img" />
            <div>
              <p>3</p>
              <p>x0.40</p>
            </div>
            <div>
              <p>4</p>
              <p>x1.00</p>
            </div>
            <div>
              <p>5</p>
              <p>x5.00</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img7} alt="img" />
            <div>
              <p>3</p>
              <p>x0.25</p>
            </div>
            <div>
              <p>4</p>
              <p>x0.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x2.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img8} alt="img" />
          <div>
              <p>3</p>
              <p>x0.25</p>
            </div>
            <div>
              <p>4</p>
              <p>x0.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x2.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img9} alt="img" />
            <div>
              <p>3</p>
              <p>x0.25</p>
            </div>
            <div>
              <p>4</p>
              <p>x0.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x2.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img10} alt="img" />
          <div>
              <p>3</p>
              <p>x0.25</p>
            </div>
            <div>
              <p>4</p>
              <p>x0.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x2.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img11} alt="img" />
            <div>
              <p>3</p>
              <p>x0.25</p>
            </div>
            <div>
              <p>4</p>
              <p>x0.50</p>
            </div>
            <div>
              <p>5</p>
              <p>x2.50</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img12} alt="img" />
            <div>
              <p>1</p>
              <p>Scatter</p>
            </div>
            <div>
              <p>3</p>
              <p>Scatter</p>
            </div>
            <div>
              <p>5</p>
              <p>Scatter</p>
            </div>
          </div>
        </div>
        <div className="slots-cont">
          <div>
            <img src={img13} alt="img" />
            <div>
              <p>2</p>
              <p>-</p>
            </div>
            <div>
              <p>3</p>
              <p>-</p>
            </div>
            <div>
              <p>4</p>
              <p>-</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlotIcons;
