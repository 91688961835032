import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./common/Layout";
import Commodity from "./components/commodity/Commodity";
import BonusC from "./components/commodity/BonusC";
import DemoC from "./components/commodity/DemoC";
import SlotC from "./components/commodity/SlotC";
import CasinoC from "./components/commodity/CasinoC";
import DownloadC from "./components/commodity/DownloadC";
import PlayC from "./components/commodity/PlayC";
import MegawaysC from "./components/commodity/MegawaysC";
import MegawaysDemoC from "./components/commodity/MegawaysDemoC";
import MegawaysMoney from "./components/commodity/MegawaysMoney";
import MegawaysOffical from "./components/commodity/MegawaysOffical";
import MegawaysOnline from "./components/commodity/MegawaysOnline";
import MegawaysSlot from "./components/commodity/MegawaysSlot";
import MegawaysPlayC from "./components/commodity/MegawaysPlayC";
import MultiholdDemo from "./components/commodity/MultiholdDemo";
import Multihold from "./components/commodity/Multihold";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Commodity />} />
            <Route path="/bonus" element={<BonusC />} />
            <Route path="/casino" element={<CasinoC />} />
            <Route path="/slot" element={<SlotC />} />
            <Route path="/demo" element={<DemoC />} />
            <Route path="/download" element={<DownloadC />} />
            <Route path="/megaways" element={<MegawaysC />} />
            <Route path="/megaways-demo" element={<MegawaysDemoC />} />
            <Route path="/megaways-money" element={<MegawaysMoney />} />
            <Route path="/megaways-offical" element={<MegawaysOffical />} />
            <Route path="/megaways-online" element={<MegawaysOnline />} />
            <Route path="/megaways-play" element={<MegawaysPlayC />} />
            <Route path="/play" element={<PlayC />} />
            <Route path="/megaways-slot" element={<MegawaysSlot />} />
            <Route path="/multihold" element={<Multihold />} />
            <Route path="/multihold-demo" element={<MultiholdDemo />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
